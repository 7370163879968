<script setup>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

import { axios } from "@/utils/axios";
import { store } from "@/store";

import AppButton from "@/components/AppButton";
import AppInvoice from "@/components/AppInvoice.vue";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const { id } = route.params;
const invoices = ref([]);
const loading = ref(true);
const totalItems = ref(0);
const typeInvoice = ref("not_paid");
const page = ref(1);
const serviceNumber = ref("");
const countInvoicesToPay = ref(0);

import useApi from "@/composables/useApi";

const { service, invoice } = useApi();

const user = ref({});
const serviceData = ref({});
const invoiceData = ref({});

const listInvoices = ref([]);

const invoiceTypes = [
  {
    value: "not_paid",
    label: t("invoices.not_payed"),
  },
  {
    value: "paid",
    label: t("invoices.paid"),
  },
];

const getService = async (id) => {
  const data = await service.getServiceById(id);
  return data.payload;
};

const getInvoicesByService = async (service_id) => {
  const data = await invoice.listInvoicesByService(service_id);
  return data.payload;
};

const loadPaymentHistory = async (service_id) => {
  const data = await invoice.getPaymentHistoryInvoicesByService(service_id);
  return data;
};

const filterInvoices = async () => {
  const payment_history = await loadPaymentHistory(id);
  if (typeInvoice.value === "paid") {
    listInvoices.value = invoiceData.value.filter(invoice => ["paid", "reversed"].includes(invoice.payment_state));
  } else {
    listInvoices.value = invoiceData.value.filter(invoice => ["not_paid", "partial"].includes(invoice.payment_state));
  }
  listInvoices.value.sort((a, b) => {
    // First sort by due date descending
    const dateCompare = new Date(b.invoice_date_due) - new Date(a.invoice_date_due);
    if (dateCompare !== 0) return dateCompare;
    // If dates are equal, sort by id ascending
    return a.id - b.id;
  });
  // listInvoices.value = listInvoices.value.filter(invoice => !payment_history.includes(invoice.id));
  listInvoices.value = listInvoices.value.map(invoice => ({
    ...invoice,
    payment_status: payment_history.includes(invoice.id) ? true : false
  }));
}

const getCountInvoicesPendingByService = async (service_id) => {
  const data = await invoice.countInvoicesPendingByService(service_id);
  return data.payload;
};

const goQuickPayment = () => {
  console.log("quick payment");
  const service_id = serviceData.value.name;
  router.push({ name: "QuickPaymentInvoices", params: { id, service_id } });
};


onMounted(async () => {
  try {
    store.APP_BACK_BUTTON = true;
    store.APP_LOADING = true;
    user.value = store.user;
    // console.log("payment_history", payment_history);
    // console.log(store.services)
    if (store.services && store.services.length > 0) {
      // console.log("[service] del store")
      serviceData.value = store.services.find(service => service.id === Number(id));
    } else {
      // console.log("[service] del api")
      serviceData.value = await getService(id);
    }
    // console.log(serviceData.value)
    if (store.invoices && store.invoices.length > 0) {
      // console.log("[invoice] del store")
      invoiceData.value = store.invoices.filter(invoice => {
        return invoice.servicio_id !== false && invoice.servicio_id[0] === Number(id);
      })
    } else {
      // console.log("[invoice] del api")
      invoiceData.value = await getInvoicesByService(id);
    }
    // console.log(invoiceData.value)
    filterInvoices();
    countInvoicesToPay.value = await getCountInvoicesPendingByService(id);
  } catch (error) {
    console.log(error);
  } finally {
    store.APP_LOADING = false;
  }
});

</script>

<template lang="pug">
//- pre {{ user }}
//- pre {{ store.services }}
//- pre {{ serviceData }}
//- pre {{ invoiceData }}
div.flex.justify-end.mb-4
  AppButton(:label="$t('login.quick') + ' (' + countInvoicesToPay + ')'" color="primary" @click="goQuickPayment()" v-if="countInvoicesToPay > 0")
span(class="label") {{ $t('invoices.filter_by') }}
select(name="invoice_type" v-model="typeInvoice" class="input" @change="filterInvoices")
  option(:value="item.value" v-for="item in invoiceTypes" :key="item.value")
    | {{ item.label }}
//- //- pre {{ invoices }}
//- pre {{ listInvoices }}
hr
//- pre {{ invoiceData }}
.cards
  .cards__body
    router-link(v-for="(invoice, i) in listInvoices" :to="{name: 'InvoicesView', params: { id: invoice.id } }" v-slot="{ navigate }" custom)
      AppInvoice(@click="navigate" :invoice="invoice")
//-   AppButton(label="Cargar más" v-if="hasMoreItems" @click="getInvoices(null)")
</template>



<style lang="scss" scoped>
.cards {
  @apply pb-4;
  &__body {
    @apply mb-4;
    @apply grid;
    @apply grid-cols-1;
    @apply gap-4;
    @screen sm {
      @apply grid-cols-2;
    }
    @screen md {
      @apply grid-cols-2;
    }
    @screen xl {
      @apply grid-cols-3;
    }
  }
}

.input {
  @apply mb-3;
  @apply block;
  @apply w-full;
  @apply rounded-md;
  @apply border-gray-300;
  @apply shadow-sm;
  @apply px-3;
  @apply py-2;
  @apply text-bluegray-700;
  @apply text-sm;
  @apply transition-all;
  @apply outline-none;
  &:focus:invalid {
    @apply border-red-300;
    @apply shadow-red-300-md;
  }
  &:focus {
    @apply border-gignetblue-300;
    @apply ring;
    @apply ring-gignetblue-200;
    @apply ring-opacity-50;
    @apply ring-offset-2;
  }

  &.disabled {
    @apply bg-bluegray-50;
    @apply text-bluegray-400;
  }
}

.label {
  @apply text-bluegray-400;
  @apply uppercase;
  @apply text-sm;
  @apply font-medium;
  @apply mb-2;
  @apply inline-block;
  @apply tracking-wide;
}
</style>
