<template lang="pug">
AppCard
  .invoice__name {{ invoice.name }}
  .invoice__ribbon
    AppRibbon(v-bind="invoiceStatus(invoice)")
  .invoice__details
    AppRow.flex-1
      AppDescription(:label="$t('invoices.invoice_date')" :description="invoice.invoice_date")
      AppDescription(:label="$t('invoices.amount_total')" :description="numberFormatter(invoice.amount_total)")
      AppDescription(:label="$t('invoices.amount_residual')" :description="numberFormatter(invoice.amount_residual)")
    .invoice__pay(v-if="showPayButton")
      .invoice__pay-button
        AppButton(:label="$t('quickpayment.pay')" :disabled="!active")
</template>

<script>
import { useI18n } from "vue-i18n";

import { dateFormatter, numberFormatter } from "@/utils/formatters";

import AppCard from "@/components/AppCard";
import AppDescription from "@/components/AppDescription";
import AppRibbon from "@/components/AppRibbon";
import AppRow from "@/components/AppRow";
import AppButton from "@/components/AppButton";

export default {
  components: { AppCard, AppButton, AppDescription, AppRibbon, AppRow },
  props: {
    invoice: Object,
    active: {
      type: Boolean,
      default: false,
    },
    showPayButton: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();

    const invoiceStatus = (invoice) => {
      if (invoice.payment_status) {
        if (invoice.payment_state === "paid") {
          return { type: "success", label: t("invoices.paid") };
        } else {
          return { type: "primary", label: t("invoices.pending_payment") };
        }
      } else {
        if (invoice.payment_state === "paid") {
          return { type: "success", label: t("invoices.paid") };
        } else if (invoice.state === "cancel") {
          return { type: "error", label: t("invoices.cancelled") };
        } else if (invoice.payment_state === "reversed") {
          return { type: "success", label: t("invoices.paid") };
        } else {
          return { type: "info", label: t("invoices.not_payed") };
        }
      }
    };

    return { dateFormatter, numberFormatter, invoiceStatus };
  },
};
</script>

<style lang="scss" scoped>
.invoice__name {
  @apply text-xs;
  @apply text-bluegray-400;
  @apply mb-2;
  @apply font-medium;
}
.invoice__ribbon {
  @apply absolute;
  @apply inset-0;
  @apply overflow-hidden;
  @apply w-full;
}
.invoice__details {
  @apply flex;
  @apply flex-col;
  @screen md {
    @apply flex-row;
  }
}
.invoice__pay {
  @screen md {
    @apply relative;
    @apply w-1/3;
  }
  &-button {
    @apply mt-3;
    @screen md {
      @apply m-0;
      @apply absolute;
      @apply -top-4;
      @apply right-8;
    }
  }
}
</style>
